import { NFTOffer } from "../models/NFT";

const options = {
    method: 'GET',
    headers: {
        accept: 'application/json',
         'X-API-KEY': `${process.env.REACT_APP_OPENSEA_API_KEY}`
    }
};

/*const options2 = {
    method: 'POST',
    headers: {
        accept: 'application/json',
         'X-API-KEY': `${process.env.REACT_APP_OPENSEA_API_KEY}`
    }
};*/
  
export const getNFTOfferList = async (asset_contract_address: string, token_id: string) => {
 const offers =  await fetch(`https://api.opensea.io//api/v2/orders/ethereum/seaport/offers?asset_contract_address=${asset_contract_address}&token_ids=${token_id}`, options)
    .then(response => response.json());

    const mappedOffers: Array<NFTOffer> = offers?.orders?.map((NFTOffer: {
        current_price: string;
        expiration_time: number;
        maker: {
            address: string;
        }
    }) => ({
        current_price: NFTOffer.current_price,
        expiration_time: NFTOffer.expiration_time,
        offerer: NFTOffer.maker.address
    }));
 return mappedOffers;
}

/*export const cancelNFTOffer = async (order_hash: string) => {
    const protocolAddress = '0x0000000000000068f116a894984e2db1123eb395';
    const cancelOffer = await fetch(`https://api.opensea.io/api/v2/orders/chain/ethereum/protocol/${protocolAddress}/${order_hash}/cancel`, options2)
    .then(res => res.json())
    .then(res => console.log(res))
    .catch(err => console.error(err));
}*/