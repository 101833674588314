import React, { useEffect, useState } from "react";
import './FrontPage.css';
import Card from "./card/Card";
import Carousel from "./carousel/Carousel";
import { getAllCollections } from "../../services/opensea/osGetNFTsByCollection";
import { NFT } from "../../services/models/NFT";
import { ColorRing } from "react-loader-spinner";
//image="https://cdn.pixabay.com/photo/2022/02/10/09/39/nft-7004985_1280.jpg"

const FrontPage = () => {
  const [loading, setLoading] = useState(true);
  const [nfts, setNFTs] = useState(Array<NFT>);

  // Fisher-Yates Shuffle Algorithm
  const shuffleArray = (array: NFT[]): NFT[] => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  
  useEffect(() => {
    getAllCollections().then((nftList: Array<NFT>) => {
      const randomizedNFTs = shuffleArray(nftList);
      setNFTs(randomizedNFTs);
      setLoading(false);
    });
  }, []);

  return (
    <div className="front-page-container">
      <h1 className="front-page-title">Welcome to ColorFuel</h1>
      <div className="carousel-container">
      {loading ? (
            <ColorRing
            visible={true}
            height="160"
            width="160"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
        ) : (
      <Carousel items={nfts} /> )}
      </div>
      <div className="front-page-content">
        <Card 
          title="ColorFuel Gallery" 
          description="Visit ColorFuel NFT Gallery" 
          image="https://cdn.pixabay.com/photo/2024/06/24/06/42/art-appraiser-8849270_1280.jpg" 
          linkTo="/gallery" 
        />
        <Card 
          title="ColorFuel Simulation" 
          description="Experience the ColorFuel 3D Gallery" 
          image="https://cdn.pixabay.com/photo/2023/06/13/16/37/ai-generated-8061337_1280.jpg" 
          linkTo="/3dgallery" 
        />
        <Card 
          title="About ColorFuel" 
          description="What is ColorFuel?" 
          image="https://cdn.pixabay.com/photo/2020/03/22/17/47/scrabble-4957948_1280.jpg" 
          linkTo="/about" 
        />
      </div>
  </div>
  );
}

export default FrontPage;