import React from "react";
import './HeaderBar.css';
import {Link} from 'react-router-dom';
import colorfuelIcon from '../../../assets/colorfuel-logo.jpeg';

const HeaderBar = () => {
    return (
      <>
      <div className="header">
        <div className="headerWrapper">
          <Link className="colorfuel-link" to="/">
          <img className="colorfuel-icon" src={colorfuelIcon} alt="instagram icon"></img>
            <h2 className="colorfuel-title">ColorFuel</h2></Link>
          <div className="header-links-container">
            <Link className="link" to="/gallery"><h4 className="header-link-text">Gallery</h4></Link>
            <Link className="link" to="/3dgallery"><h4 className="header-link-text">Simulation</h4></Link>
          </div>
          <div className="wallet-connect-container">
            <w3m-button/>
          </div>
        </div>
      </div>
      </>
    );
}

export default HeaderBar;