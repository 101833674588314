import React from "react";
import "./OfferList.css";
import { formatUnits } from "ethers";
import offersListIcon from "../../../assets/offers-list.svg";

const OfferAddressLink = ({ address }) => {
  const etherscanUrl = `https://etherscan.io/address/${address}`;

  return (
    <a href={etherscanUrl} className="offers-address" target="_blank" rel="noopener noreferrer">{address}</a>
  );
};

const OfferList = ({offers}) => {
    return (
        <div className="offersContainer">
            <div className="offersContent gradient">
              <div className="offers-header">
              <img className="offers-list-icon" src={offersListIcon} alt="offers list icon" />
              <h2>Offers</h2>
              </div>
                {
                  offers.length !== 0 ? (
                    <div className="table-container">
                      <table className="offer-list">
                        <thead>
                          <tr>
                            <th>Price</th>
                            <th>Expiration</th>
                            <th>From</th>
                          </tr>
                        </thead>
                        <tbody>
                          {offers.map((offer, index) => (
                            <tr key={index}>
                              {
                                offer.current_price && 
                                <td data-label="Price">
                                  {formatUnits(offer.current_price, 18)} WETH
                                </td>
                              }
                              <td data-label="Expiration">
                                {new Date(offer.expiration_time * 1000).toISOString().split("T")[0]}{" "}
                                {new Date(offer.expiration_time * 1000).toTimeString().split(" ")[0]}
                              </td>
                              <td data-label="From"><OfferAddressLink address={offer.offerer} /></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>
                      <h4>No offers yet</h4>
                    </div>
                  )
                }
            </div>
        </div>
    );
}

export default OfferList;