import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

interface CardProps {
  title: string;
  description: string;
  image: string;
  linkTo: string;
}

const Card: React.FC<CardProps> = ({ title, description, image, linkTo }) => {
  return (
    <Link to={linkTo} className="card">
      <img src={image} alt={title} className="card-image" />
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        <p className="card-description">{description}</p>
      </div>
    </Link>
  );
};

export default Card;
