import { NFTOwner } from "../models/NFT";

const options = {
    method: 'GET',
    headers: {
        accept: 'application/json',
         'X-API-KEY': `${process.env.REACT_APP_OPENSEA_API_KEY}`
    }
};
  
export const getNFTOwner = async (asset_contract_address: string, token_id: string) => {
 const nftData =  await fetch(`https://api.opensea.io/api/v2/chain/ethereum/contract/${asset_contract_address}/nfts/${token_id}`, options)
 .then(res => res.json());

 const owner: NFTOwner = {
    ownerWalletAddress: nftData?.nft?.owners[0]?.address || '',
 }

 return owner;
}