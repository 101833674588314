import { createAppKit } from '@reown/appkit/react'
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import { WagmiProvider } from 'wagmi'
import { mainnet, solana } from '@reown/appkit/networks'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SolanaAdapter } from '@reown/appkit-adapter-solana/react'
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets'
import React from 'react';

const projectId = `${process.env.REACT_APP_WALLETCONNECT_API_KEY}`
if (!projectId) {
  throw new Error('Project Id is not defined.')
}

const queryClient = new QueryClient();

const metadata = {
  name: 'ColorFuel',
  description: 'ColorFuel Web3 Connect',
  url: 'https://colorfuel.art',
  icons: ['']
}

const networks = [mainnet]

// 4. Create Wagmi Adapter - Ethereum
const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId,
  ssr: true
});

// Initialize Solana Wallets
const solanaWallets = [new PhantomWalletAdapter(), new SolflareWalletAdapter()];

// Create SolanaAdapter
const solanaAdapter = new SolanaAdapter({
  wallets: solanaWallets
});

createAppKit({
  adapters: [wagmiAdapter, solanaAdapter],
  networks: [mainnet, solana],
  defaultNetwork: mainnet,
  projectId,
  metadata,
  features: {
    analytics: true,
    email: false,
    socials: [],
  }
})

export function AppKitProvider ({ children }){
  return (
      <WagmiProvider config={wagmiAdapter.wagmiConfig}>
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
      </WagmiProvider>
  )
}