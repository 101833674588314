import React, { useState, useEffect, useCallback } from 'react';
import './Carousel.css';
import { NFT } from '../../../services/models/NFT';
import { Link } from 'react-router-dom';

interface CarouselProps {
  items: NFT[];
}

const Carousel: React.FC<CarouselProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerSlide = Math.floor(window.innerWidth / 245); // Calculate items that fit in the viewport

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + itemsPerSlide) % items.length);
  }, [items.length, itemsPerSlide]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - itemsPerSlide + items.length) % items.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 7500); // Slide every 7.5 seconds

    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <div className="carousel">
      <button className="carousel-arrow left" onClick={prevSlide}>
        &#10094;
      </button>
      <div className="carousel-inner">
        <div
          className="carousel-track"
          style={{
            transform: `translateX(-${currentIndex * 200}px)`,
          }}
        >
          {items.map((item, index) => (
            <Link
              to={`/picture/${encodeURIComponent(item.name)}`}
              className="carousel-item"
              key={index}
            >
              <img src={item.image_url} alt={item.name} className="carousel-image" />
              <div className="carousel-overlay">
                <h3 className="carousel-title">{item.name}</h3>
                <p className="carousel-description">{item.description}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <button className="carousel-arrow right" onClick={nextSlide}>
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;
