import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAllCollections } from '../../services/opensea/osGetNFTsByCollection';
import "./PicturePage.css";
import { ColorRing } from "react-loader-spinner";
import openseaIcon from "../../assets/opensea-icon.svg";
import etherscanIcon from "../../assets/etherscan-logo.svg";
import { NFT, NFTOffer } from "../../services/models/NFT";
import MakeOffer from "./makeOffer/MakeOffer";
import { useAccount } from 'wagmi';
import { useAppKit } from '@reown/appkit/react';
import OfferList from "./offerList/OfferList";
import PicturePageHeader from "./header/PicturePageHeader";
import { getNFTOwner } from "../../services/opensea/osGetNFT";
import { useEnsName } from "wagmi";
import { getNFTOfferList } from "../../services/opensea/osGetOfferList";

type MessageType = 'success' | 'error';

interface PopupProps {
  message: string;
  messageType: MessageType;
  selectedNFT: NFT | undefined;
  getNFTOfferList: (contract: string, identifier: string) => Promise<any>;
  setSelectedNFTOffers: (offers: any[]) => void; // Pass the setter function
}

const Popup: React.FC<PopupProps> = ({
  message,
  messageType,
  selectedNFT,
  getNFTOfferList,
  setSelectedNFTOffers,
})=> { 
  const [loading, setLoading] = useState(false);

  const refetchOffers = async () => {
    if (selectedNFT) {
      setLoading(true);
      try {
        const offers = await getNFTOfferList(selectedNFT.contract, selectedNFT.identifier);
        setSelectedNFTOffers(offers);
      } catch (error) {
        console.error("Failed to refetch offers:", error);
      } finally {
        setLoading(false);
      }
    }
  };
 
  return (
 <div className={`popup ${messageType}`}>
    {messageType === 'success' ? (
        <><svg className="icon" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm-1.707 15.293l-3.586-3.586 1.414-1.414L10.293 13l5.293-5.293 1.414 1.414-6.707 6.707z" />
      </svg>
      <p className="popup-text">{message}</p>
          <div className="offersLinkWrapper">
          <button onClick={refetchOffers} disabled={loading} className="popup-button">
            {loading ? "Refreshing..." : "Refresh Offers"}
          </button>
          </div>
          </>
    ) : (
        <><svg className="icon" viewBox="0 0 24 24">
          <path fill="currentColor" d="M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm1 17h-2v-2h2v2zm0-4h-2V7h2v6z" />
        </svg><p>{message}</p></>
    )}
  </div>
 );
};

const AddressLink = ({ showAddress, address }) => {
  const etherscanUrl = `https://etherscan.io/address/${address}`;

  return (
    <a href={etherscanUrl} className="nftOwnerLink"target="_blank" rel="noopener noreferrer">
            <div className="marquee">
        <span>{showAddress}</span>
      </div>
    </a>
  );
};

const PicturePage = () => {
  const routeParams = useParams();
  const [loading, setLoading] = useState(true);
  const [etherscanURL, setEtherscanURL] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState<NFT | undefined>();
  const [selectedNFTOffers, setSelectedNFTOffers] = useState<NFTOffer[] | undefined>();
  const [selectedNFTOwner, setSelectedNFTOwner] = useState<`0x${string}`| undefined>();
  const [isMakeOfferModalOpen, setMakeOfferModalOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<MessageType>('success');
  const { isConnected } = useAccount();
  
  const { open } = useAppKit();

  useEffect(() => {
    const fetchNFTData = async () => {
      try {
        // Fetch all collections and find the matching NFT
        const nfts = await getAllCollections();
        const nft = nfts.find((nft) => nft.name === routeParams.name);
  
        if (nft) {
          setSelectedNFT(nft);
          const offers = await getNFTOfferList(nft.contract, nft.identifier);
          setSelectedNFTOffers(offers);
          // Check if the collection matches specific types
          if (
            nft.collection === 'colorfuel-universal' ||
            nft.collection === 'colorfuel-primer' ||
            nft.collection === 'colorfuel-nostalgia'
          ) {
            setEtherscanURL(true);
          }
          // Fetch NFT owner
          const nftOwner = await getNFTOwner(nft.contract, nft.identifier);
          if (nftOwner) {
            setSelectedNFTOwner(nftOwner.ownerWalletAddress as `0x${string}`);
          }
        }
      } catch (error) {
        console.error('Error fetching NFT data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchNFTData();
  }, [routeParams.name]);

  const { data: ensName } = useEnsName({
    address: selectedNFTOwner || undefined, // Pass undefined if the address is null
  });


  const openMakeOfferModal = (value: boolean, message: string, type: 'success' | 'error') => {
    if(isConnected){
      setMakeOfferModalOpen(value);
      setMessageType(type);
      setPopupMessage(message);
    }
    else {
      open({ view : 'Connect' });
    }
  }

  return (
    <>
      <div className="pictureWrapper">
        {loading ? (
            <ColorRing
            visible={true}
            height="160"
            width="160"
            ariaLabel="color-ring-loading"
            wrapperStyle={{
              marginTop: '100px',
              marginBottom: '100px'
            }}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
        ) : (
          <>
          <div className="mainContainer">
            <div className="pictureInfoContent">
            <PicturePageHeader />
                <div className="pictureContainer-img">
                  <img
                    className="picture"
                    key={selectedNFT?.name}
                    src={selectedNFT?.image_url}
                    alt="nft"
                  />
                </div>
                <div className="offersAndInfoContainer">
                  <div className="pictureContainer gradient">
                    <div className="nftTextInfo">
                      <h1>{selectedNFT?.name.replace(/^#\d+\s*/, '')}</h1>
                      <p className="nftDescription">{selectedNFT?.description}</p>
                      <h4 className="nftDescription">Owner:</h4>
                      <AddressLink showAddress={ensName} address={selectedNFTOwner}/>
                    </div>
                    <div className="pictureButtonContainer">
                      <button className="makeOfferButton"
                        onClick={() => openMakeOfferModal(true, "", messageType)}>
                        <div className="pictureButtonContent">
                          <p>Make Offer</p>
                        </div>
                      </button>

                      {isMakeOfferModalOpen && 
                        <MakeOffer onClose={openMakeOfferModal} selectedNFT={selectedNFT} />
                      }
                      {popupMessage &&
                        <Popup message={popupMessage} messageType={messageType}   selectedNFT={selectedNFT}
                        getNFTOfferList={getNFTOfferList}
                        setSelectedNFTOffers={setSelectedNFTOffers}/>
                      }
                      <div className="externalLinkContainer">
                        <button>
                          <div className="externalLinkWrapper"> {
                          etherscanURL
                          ?
                          <Link
                          className="externalLink"
                          to={`https://etherscan.io/address/${selectedNFT?.contract}`}
                          target="_blank"
                        >
                          <div className="pictureButtonContent">
                            <img src={etherscanIcon} alt="etherscan icon" />
                          </div>
                        </Link>
                        :
                        <Link
                        className="externalLink"
                        to={`https://etherscan.io/nft/${selectedNFT?.contract}/${selectedNFT?.identifier}`}
                        target="_blank"
                      >
                        <div className="pictureButtonContent">
                          <img src={etherscanIcon} alt="etherscan icon" />
                        </div>
                      </Link>
                          }
                          </div>
                        </button>
                        <button>
                          <div className="externalLinkWrapper">
                          <Link
                            className="externalLink"
                            to={`https://opensea.io/assets/ethereum/${selectedNFT?.contract}/${selectedNFT?.identifier}`}
                            target="_blank"
                          >
                            <div className="pictureButtonContent">
                              <img src={openseaIcon} alt="opensea icon" />
                            </div>
                          </Link>
                          </div>
                        </button>            
                      </div>
                    </div>
                  </div>
                  <OfferList offers={selectedNFTOffers || []}/>
                </div>
            </div>
          </div>
          </>
        )}
      </div>
    </>
  );
};

export default PicturePage;
