
import React from "react";
import './SocialMediaBar.css';
import { Link } from "react-router-dom";
import instagramIcon from '../../../assets/instagram-icon.svg';
import tiktokIcon from '../../../assets/tiktok-icon.svg';
import xIcon from '../../../assets/x-icon.svg';
import youtubeIcon from '../../../assets/youtube-icon.svg';
import facebookIcon from '../../../assets/facebook-icon.svg';
import pinterestIcon from '../../../assets/pinterest-icon.svg';
import tumblrIcon from '../../../assets/tumblr-icon.svg';
import githubIcon from '../../../assets/github-icon.svg';
import openseaIcon from '../../../assets/opensea-black-icon.svg';
import threadsIcon from '../../../assets/threads-icon.png';

const SocialMediaBar = () => {
    return (
      <>
        <div className="social-media-bar-wrapper">
          <Link className="social-media-link" to="https://www.instagram.com/colorfuel.art.studio/" target="_blank" rel="noopener noreferrer">
          <img src={instagramIcon} alt="instagram icon"></img>
          </Link>
          <Link className="social-media-link" to="https://www.tiktok.com/@colorfuel?lang=en" target="_blank" rel="noopener noreferrer">
          <img src={tiktokIcon} alt="tiktok icon"></img>
          </Link>
          <Link className="social-media-link" to="https://twitter.com/ColorFuel_Art" target="_blank" rel="noopener noreferrer">
          <img src={xIcon} alt="x icon"></img>
          </Link>
          <Link className="social-media-link" to="https://www.youtube.com/@colorfuel.art.studio" target="_blank" rel="noopener noreferrer">
          <img src={youtubeIcon} alt="youtube icon"></img>
          </Link>
          <Link className="social-media-link" to="https://www.facebook.com/profile.php?id=100092966033792" target="_blank" rel="noopener noreferrer">
          <img src={facebookIcon} alt="facebook icon"></img>
          </Link>
          <Link className="social-media-link" to="https://no.pinterest.com/colorfuelartstudio/" target="_blank" rel="noopener noreferrer">
          <img src={pinterestIcon} alt="pinterest icon"></img>
          </Link>
          <Link className="social-media-link" to="https://www.tumblr.com/colorfuel-art" target="_blank" rel="noopener noreferrer">
          <img src={tumblrIcon} alt="tumblr icon"></img>
          </Link>
          <Link className="social-media-link" to="https://www.threads.net/@colorfuel.art.studio" target="_blank" rel="noopener noreferrer">
          <img src={threadsIcon} alt=""></img>
          </Link>
          <Link className="social-media-link" to="https://github.com/ColorFuelTechnology" target="_blank" rel="noopener noreferrer">
          <img src={githubIcon} alt="github icon"></img>
          </Link>
          <Link className="social-media-link" to="https://opensea.io/colorfuel_art_studio" target="_blank" rel="noopener noreferrer">
          <img src={openseaIcon} alt="opensea icon"></img>
          </Link>

          <div className="contact-container">
              <h3>colorfuelartstudio@gmail.com</h3>
          </div>
        </div>
      </>
    );
}

export default SocialMediaBar;
