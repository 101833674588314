import React from "react"; 
import "./PicturePageHeader.css";
import { Link } from "react-router-dom";
import backArrowIcon from "../../../assets/back-arrow.svg";

const PicturePageHeader = () => {
    return (
        <div className="pictureHeaderContainer">
            <Link className="galleryLink" to="/gallery">
                <div className="redirectGalleryButtonContainer">
                    <img className="back-arrow-icon"src={backArrowIcon} alt="backarrow icon" />
                    <h3>Gallery</h3>
                </div>
            </Link>
        </div>
    );
}

export default PicturePageHeader;