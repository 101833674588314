import React from "react";
import './AboutPage.css';

const AboutPage = () => {
  
  return (
    <>
    <div className="aboutWrapper">
      <h1>Frequently Asked Questions</h1>
      <p className="about">
        This is ColorFuel,
        abstract art that layers multiple platforms and technologies in both the physical and digital world.
      </p>
      <p className="about">
        For any inquiries, please reach out on email: <b>colorfuelartstudio@gmail.com</b>
      </p>
    </div>
    </>
  );
}

export default AboutPage;