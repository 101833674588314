import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Gallery.css";
import { getAllCollections } from "../../services/opensea/osGetNFTsByCollection";
import { ColorRing } from "react-loader-spinner";
import { NFT } from "../../services/models/NFT";
import ProgressiveImage from "react-progressive-graceful-image";
import imageSizeOne from "../../assets/icon-size-1.png"
import imageSizeTwo from "../../assets/icon-size-2.png"
import imageSizeThree from "../../assets/icon-size-3.png"
//import { fetchSolanaNFTs } from "../../services/solana/getNFTsByAccount";

const Gallery = () => {
  const [nfts, setNFTs] = useState(Array<NFT>);
  const [filteredNFTs, setFilteredNFTs] = useState(Array<NFT>);
  const [chapters, setChapters] = useState(Array<String>);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState<String | null>("All");
  const [resize, setResize] = useState<string | undefined>("one");
  const [resizeImage, setResizeImage] = useState<string | undefined>("one-image");
  const [resizeImageText, setResizeImageText] = useState<string | undefined>("one-image-text");
  //const creatorAddress = '2xDD4i4GgNwLcY3W4sBEFiAHTAkYNmr1VPwjAU5GoN8e'; 

  const filterItem = (chapter: String) => {
    const updateItems = nfts.filter((curElem) => {
      return curElem.collection === chapter;
    });

    if(chapter === "All"){
      setFilteredNFTs(nfts);
    }
    else
    {
      setFilteredNFTs(updateItems);
    }
    setActive(chapter);
  };

  const resizePictures = (size: string) => {
    setResize(size);
    const swImgSize = resizeTranslater(size);
    const swImgTextSize = resizeTextTranslater(size);
    setResizeImageText(swImgTextSize);
    setResizeImage(swImgSize);

  }

  const resizeTranslater = (size: string) => {
    switch (size) {
      case "one":
        return "one-image";
      case "two":
        return "two-image";
      case "three":
        return "three-image";
      default:
        return "one-image";
    }
  }

  const resizeTextTranslater = (size: string) => {
    switch (size) {
      case "one":
        return "one-image-text";
      case "two":
        return "two-image-text";
      case "three":
        return "three-image-text";
      default:
        return "one-image-text";
    }
  }

  const chapterSubstring = (chapter: String) => {
    const ch = chapter.split("-").pop() ?? "";
    const capitalized = ch.charAt(0).toUpperCase() + ch.slice(1);
    return capitalized;
  }

  useEffect(() => {
    getAllCollections().then((nftList: Array<NFT>) => {
      setNFTs(nftList);
      setFilteredNFTs(nftList);
      let unique_values = [
        ...new Set(nftList.map((element) => element.collection)),
      ];
      setChapters(unique_values);
      setLoading(false);
    });
    //fetchSolanaNFTs(creatorAddress);
  }, []);

  return (
    <>
    <div className="gallery-content">
      <div className="gallery-options">
        <div className="gallery-filter">
          {
            !loading ? (
              <button onClick={() => filterItem("All")} className={active === "All" ? "button all-btn selected" : "button all-btn"}>
                <div>
                All
                </div>
              </button>
            ) : null
          }
          {
            !loading ? (
              chapters.map((c: String, i: number) => (
                <button key={i} onClick={() => filterItem(c)} className={active === c ? "button selected" : "button"}>
                  <div>
                    {chapterSubstring(c)}
                  </div>
                </button>
              ))
            ) : null
          }
        </div>
        {
        !loading ? (
          <div className="gallery-size">
              <div onClick={() => resizePictures("one")} className={resize === "one" ? "button selected" : "button"}>
                <img className="size-icon-one" src={imageSizeOne} alt="size icon one" />
              </div>
              <div onClick={() => resizePictures("two")} className={resize === "two" ? "button selected" : "button"}>
                <img className="size-icon-two" src={imageSizeTwo} alt="size icon two" />
              </div>
              <div onClick={() => resizePictures("three")} className={resize === "three" ? "button selected" : "button"}>
                <img className="size-icon-three" src={imageSizeThree} alt="size icon three" />
              </div>
          </div>
        ) : null
        }
      </div>
      <div className="gallery-container">
        {loading ? (
            <ColorRing
            visible={true}
            height="160"
            width="160"
            ariaLabel="color-ring-loading"
            wrapperStyle={{
              marginTop: '100px',
              marginBottom: '100px'
            }}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
        ) : (
          filteredNFTs?.map((art: NFT, index: number) => (
            <div key={index} className={resizeImage}>
              <Link to={`/picture/${encodeURIComponent(art.name)}`} className="image-container shine">
                <div className={"image-content " + resize}>
                      <ProgressiveImage
                    src={art.image_url}
                    placeholder="./colorfuel-logo.jpg"
                  >
                    {(src, loading) => (
                      <img
                        className={`image${loading ? " loading " : " loaded "}` +resize}
                        src={src}
                        alt="nft"
                      />
                    )}
                  </ProgressiveImage>
                  </div>
              </Link>
              <div className={"image-text-box gradient hide-" + resizeImageText}>
                <h3 className={"image-text " + resizeImageText}>{art.name.replace(/^#\d+\s*/, '')}</h3>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
    </>
  );
};

export default Gallery;
